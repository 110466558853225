import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import L from "leaflet";
import redPinIcon from "../../Assets/images/pin.png";
import Topbar from "../global/Topbar";
import { fetchViewClient } from "../../Redux/Slice/Client/ViewClientSlice";
import { addClient } from "../../Redux/Slice/Client/AddClientSlice";
import { UpdateClient } from "../../Redux/Slice/Client/UpdateClientSaga";
import { fetchViewAdminProjects } from "../../Redux/Slice/Admin/Projects/ViewProjectsSlice";
import { UpdateAdminProjects } from "../../Redux/Slice/Admin/Projects/UpdateProjectsSlice";
import ExcelDownload from "../Excel/ExcelDownload";
import { fetchViewAdminVisits } from "../../Redux/Slice/Admin/Visits/ViewAdminVisitsSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Projects() {
  const dispatch = useDispatch();


  const Visits = useSelector(
    (state) => state.ViewAdminVisits.ViewAdminVisitsData?.data
  );
  const ProjectsData = useSelector(
    (state) => state.ViewAdminProjects.ViewAdminProjectsData?.data
  );
  console.log("ProjectsData", ProjectsData);
  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [25, 30], // Adjust the size as needed
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  const loading = useSelector((state) => state.ViewAdminProjects.isLoader);
  const error = useSelector((state) => state.ViewAdminProjects.isError);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);

  // view list
  React.useEffect(() => {
    dispatch(fetchViewClient());
    dispatch(fetchViewAdminProjects());
    dispatch(fetchViewAdminVisits());
  }, [dispatch]);
  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // add Projects
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [formData, setFormData] = useState({
    clientName: "",
    address: "",
    concered_person: "",
    phone: "",
    email: "",
    designation: "",
    categoery_id: "5",
    device_time: "",
  });
  const validateForm = () => {
    const errors = {};

    if (!formData.clientName.trim()) {
      errors.clientName = "Firm Name is required";
    }
    if (!formData.address.trim()) {
      errors.address = "Address is required";
    }
    if (!formData.concered_person.trim()) {
      errors.concered_person = "Concerned Person is required";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone Number is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    }
    if (!formData.designation.trim()) {
      errors.designation = "Designation is required";
    }

    return errors;
  };
  const [formErrors, setFormErrors] = useState({
    clientName: "",
    address: "",
    concered_person: "",
    phone: "",
    email: "",
    designation: "",
    device_time: "",
  });
  const handleForm = () => {
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const formDataWithDefaults = {
      ...formData,
      categoery_id: "5",
    };

    // Log the form data before dispatching
    console.log("Form Data:", formDataWithDefaults);

    dispatch(addClient(formDataWithDefaults))
      .then(() => {
        // Reset form data and errors after successful addition
        setFormData({
          clientName: "",
          address: "",
          concered_person: "",
          phone: "",
          email: "",
          designation: "",
          device_time: "",
        });
        setFormErrors({});

        // Reload data after successful addition
        dispatch(fetchViewClient());

        // Close the modal
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adding Projects:", error);
        toast.error("Error adding Projects. Please try again.");
      });
  };

  const handleChange = (e) => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      device_time: formattedDate,
    });
  };

  //   update
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [editData, setEditData] = React.useState({
    id: "",
    name: "",
    type: "",
    address: "",
    phase: "",
    phone: "",
    concern_person: "",
    remark: "",
    latitude: "",
    longitude: "",
  });

  const handleEditModalChange = (field, value) => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setEditData((prevData) => ({
      ...prevData,
      [field]: value,
      device_time: formattedDate, // Include device_time in the state update
    }));
  };

  const handleEdit = () => {
    const {
      id,
      name,
      type,
      address,
      phase,
      phone,
      concered_person: concern_person,
      remark,
      latitude,
      longitude,
    } = editData;

    const credentials = {
      id,
      name,
      type,
      address,
      phase,
      phone,
      concern_person,
      remark,
      latitude,
      longitude,
    };

    dispatch(UpdateAdminProjects(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchViewAdminProjects());

        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Projects:", error);
        toast.error("Error updating Projects. Please try again.");
      });
  };

  const handleOpenEditModal = (ProjectsData) => {
    if (ProjectsData) {
      setSelectedData(ProjectsData);
      setEditData({
        id: ProjectsData.id,
        name: ProjectsData.name,
        address: ProjectsData.address,
        concered_person: ProjectsData.concered_person,
        phone: ProjectsData.phone,
        remark: ProjectsData.remark,
        phase: ProjectsData.phase,
        type: ProjectsData.type,
        latitude: ProjectsData.latitude,
        longitude: ProjectsData.longitude,
      });
    } else {
      setEditData({
        id: "",
        name: "",
        type: "",
        address: "",
        phase: "",
        phone: "",
        concern_person: "",
        remark: "",
        latitude: "",
        longitude: "",
      });
    }
    setOpenEditModal(true);
  };
  //   search
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  const filteredData = ProjectsData?.filter(
    (ProjectsData) =>
      ProjectsData.concered_person.toLowerCase().includes(searchTerm) ||
      ProjectsData.type.toLowerCase().includes(searchTerm)
  );

  const calculateTotalAmount = React.useCallback(
    (projectId) => {
      return Visits?.reduce((total, visitData) => {

        if (
          visitData.visits?.firm_id == projectId &&
          visitData.visits?.category === "Project"
        ) {
          const amount = visitData.visits.amount.replace(/[^0-9.-]+/g, '');
          console.log('amount:', amount); // <--- Add this log
          const parsedAmount = parseFloat(amount);
          console.log('parsedAmount:', parsedAmount); // <--- Add this log
          if (isNaN(parsedAmount)) {
            console.error('Error parsing amount:', amount);
            return total;
          }
          return total + parsedAmount;
        }
        return total;
      }, 0);
    },
    [Visits]
  );


  // Add totalAmount to each dealerData and sort by totalAmount in descending order
  const sortedData = filteredData
    ?.map((projectData) => ({
      ...projectData,
      amount: calculateTotalAmount(projectData?.id),
    }))

  console.log("sorted project amount => " + JSON.stringify(sortedData))

  // Slice the data for pagination
  const slicedData = sortedData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const [openMapModal, setOpenMapModal] = React.useState(false);
  const [mapLocation, setMapLocation] = React.useState({
    latitude: 0,
    longitude: 0,
  });

  const handleOpenMapModal = (ProjectsData) => {
    const latitude = parseFloat(ProjectsData.latitude);
    const longitude = parseFloat(ProjectsData.longitude);

    // Check if latitude and longitude are available
    if (isNaN(latitude) || isNaN(longitude)) {
      // Handle the case where latitude and/or longitude are not available
      toast.error(
        "Latitude and/or Longitude not available for this Architect."
      );
      return;
    }

    setMapLocation({
      latitude: latitude,
      longitude: longitude,
    });
    setOpenMapModal(true);
  };

  const handleCloseMapModal = () => {
    setOpenMapModal(false);
  };

  const handleRemoveGeotagged = () => {
    // Display a confirmation dialog
    const confirmed = window.confirm(
      "Are you sure you want to remove the geotagged coordinates?"
    );

    // If the user confirms, update the state to remove geotagged coordinates
    if (confirmed) {
      setEditData((prevData) => ({
        ...prevData,
        latitude: null,
        longitude: null,
      }));
    }
  };
  const [downloadExcel, setdownloadExcel] = useState(false);
  const [excelData, setexcelData] = useState();
  const donloadexcel = () => {
    setdownloadExcel(true);
    const table = document.getElementById("exportTable");
    setexcelData(table);
  };

  const disabledownload = () => {
    setdownloadExcel(false);
  };
  return (
    <>
      <Dialog open={openEditModal} maxWidth="md" onClose={handleCloseModal}>
        <DialogTitle
          sx={{ background: "linear-gradient(to right, #fff, #bfbfbf)" }}
        >
          Update Projects
        </DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <input
                  type="hidden"
                  id="eid"
                  value={selectedData ? selectedData.id : ""}
                />

                <Grid item xs={4}>
                  <h5>Type*</h5>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="role_id">Type</InputLabel>
                    <Select
                      label="Type"
                      name="type"
                      id="type"
                      defaultValue={selectedData ? selectedData.type : ""}
                      onChange={(event) =>
                        handleChange(event.target.value, "type")
                      } // Updated line
                      error={!!formErrors.type}
                      helperText={formErrors.type}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    >
                      <MenuItem value="Commercial">Commercial</MenuItem>
                      <MenuItem value="Residential">Residential</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <h5>Project Name</h5>
                  <TextField
                    id="name"
                    label="Project Name"
                    defaultValue={selectedData ? selectedData.name : ""}
                    onChange={(e) =>
                      handleEditModalChange("name", e.target.value)
                    }
                    fullWidth
                    margin="normal"
                    error={!!formErrors.clientName}
                    helperText={formErrors.clientName}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <h5>Concerned Person</h5>
                  <TextField
                    label="Concerned Person"
                    fullWidth
                    margin="normal"
                    name="concern_person"
                    id="concern_person"
                    defaultValue={
                      selectedData ? selectedData.concered_person : ""
                    }
                    onChange={(e) =>
                      handleEditModalChange("concered_person", e.target.value)
                    }
                    error={!!formErrors.concered_person}
                    helperText={formErrors.concered_person}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <h5>Phone</h5>
                  <TextField
                    label="Phone"
                    fullWidth
                    margin="normal"
                    name="phone"
                    id="phone"
                    defaultValue={selectedData ? selectedData.phone : ""}
                    onChange={(e) =>
                      handleEditModalChange("phone", e.target.value)
                    }
                    error={!!formErrors.phone}
                    helperText={formErrors.phone}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <h5>Phase</h5>
                  <TextField
                    label="Phase"
                    fullWidth
                    margin="normal"
                    name="phase"
                    id="phase"
                    defaultValue={selectedData ? selectedData.phase : ""}
                    onChange={(e) =>
                      handleEditModalChange("phase", e.target.value)
                    }
                    error={!!formErrors.phase}
                    helperText={formErrors.phase}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
                <Grid item xs={4} style={{ marginTop: "80px" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRemoveGeotagged}
                  >
                    Remove Geotagged
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <h5>Address</h5>
                  <TextField
                    label="Address"
                    fullWidth
                    multiline
                    rows={4} // Adjust the number of rows as needed
                    margin="normal"
                    name="address"
                    id="address"
                    defaultValue={selectedData ? selectedData.address : ""}
                    onChange={(e) =>
                      handleEditModalChange("address", e.target.value)
                    }
                    error={!!formErrors.address}
                    helperText={formErrors.address}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>remark</h5>
                  <TextField
                    label="remark"
                    fullWidth
                    multiline
                    rows={4} // Adjust the number of rows as needed
                    margin="normal"
                    name="remark"
                    id="remark"
                    defaultValue={selectedData ? selectedData.remark : ""}
                    onChange={(e) =>
                      handleEditModalChange("remark", e.target.value)
                    }
                    error={!!formErrors.remark}
                    helperText={formErrors.remark}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>
        <DialogActions
          sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
        >
          <Button
            onClick={handleCloseEditModal}
            color="secondary"
            style={{
              background: "#fff",
              height: "100%",
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleEdit}
            style={{
              background: "linear-gradient(to right, #2d0031, #4d0054)",
              height: "100%",
              color: "white",
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd", // Border color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            borderRadius: "5px", // Border radius
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          {/* <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#fff" }}
            startIcon={<AddIcon />}
            onClick={handleOpenModal}
          >
            Add Projects
          </Button> */}
          <h4 style={{ color: "#ffffff" }}>Projects Details</h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputBase
              sx={{ ml: 2, width: "220px" }}
              placeholder="Search by User or Concerned Person"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff", // Background color
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
                outline: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
              }}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#fff" }}
            onClick={donloadexcel}
          >
            Download
          </Button>
        </div>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : filteredData && filteredData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp name
                    </StyledTableCell>

                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Type
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Project Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Address
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Phase
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Concerned Person
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Phone
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Remark
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Amount
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Action
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Map
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((ProjectsData, index) => (
                    <StyledTableRow key={ProjectsData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>{ProjectsData.empName}</StyledTableCell>
                      <StyledTableCell>{ProjectsData.type}</StyledTableCell>
                      <StyledTableCell>{ProjectsData.name}</StyledTableCell>
                      <StyledTableCell>{ProjectsData.address}</StyledTableCell>
                      <StyledTableCell>{ProjectsData.phase}</StyledTableCell>
                      <StyledTableCell>
                        {ProjectsData.concered_person}
                      </StyledTableCell>
                      <StyledTableCell>{ProjectsData.phone}</StyledTableCell>
                      <StyledTableCell>{ProjectsData.remark}</StyledTableCell>
                      <StyledTableCell>{ProjectsData.amount}</StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenEditModal(ProjectsData)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell>
                        {ProjectsData.latitude && ProjectsData.longitude ? (
                          <IconButton
                            color="secondary"
                            onClick={() => handleOpenMapModal(ProjectsData)}
                          >
                            <MapIcon />
                          </IconButton>
                        ) : null}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, filteredData.length]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Projects available</div>
        )}
        <Dialog open={openModal} maxWidth="md" onClose={handleCloseModal}>
          <DialogTitle
            sx={{ background: "linear-gradient(to right, #fff, #bfbfbf)" }}
          >
            Add Project
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Firm Name</h5>
                    <TextField
                      label="Firm Name"
                      fullWidth
                      margin="normal"
                      name="clientName"
                      id="clientName"
                      value={formData.clientName}
                      onChange={handleChange}
                      error={!!formErrors.clientName}
                      helperText={formErrors.clientName}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <h5>Concerned Person</h5>
                    <TextField
                      label="Concerned Person"
                      fullWidth
                      margin="normal"
                      name="concered_person"
                      id="concered_person"
                      value={formData.concered_person}
                      onChange={handleChange}
                      error={!!formErrors.concered_person}
                      helperText={formErrors.concered_person}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Phone</h5>
                    <TextField
                      label="Phone"
                      fullWidth
                      margin="normal"
                      name="phone"
                      id="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      error={!!formErrors.phone}
                      helperText={formErrors.phone}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Email</h5>
                    <TextField
                      label="Email"
                      fullWidth
                      margin="normal"
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!formErrors.email}
                      helperText={formErrors.email}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Designation</h5>
                    <TextField
                      label="Designation"
                      fullWidth
                      margin="normal"
                      name="designation"
                      id="designation"
                      value={formData.designation}
                      onChange={handleChange}
                      error={!!formErrors.designation}
                      helperText={formErrors.designation}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h5>Address</h5>
                    <TextField
                      label="Address"
                      fullWidth
                      multiline
                      rows={4} // Adjust the number of rows as needed
                      margin="normal"
                      name="address"
                      id="address"
                      value={formData.address}
                      onChange={handleChange}
                      error={!!formErrors.address}
                      helperText={formErrors.address}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </div>

          <DialogActions
            sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
          >
            <Button
              onClick={handleCloseModal}
              color="secondary"
              style={{
                background: "#fff",
                height: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleForm}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <div style={{ display: downloadExcel ? "block" : "none" }}>
          {downloadExcel ? (
            <ExcelDownload
              data={excelData}
              name="Project-data"
              onsubmit={disabledownload}
            />
          ) : (
            <></>
          )}
        </div>
        <Dialog open={openMapModal} onClose={handleCloseMapModal}>
          <DialogTitle>Location on Map</DialogTitle>
          <DialogContent>
            <MapContainer
              center={[mapLocation.latitude, mapLocation.longitude]}
              zoom={13}
              style={{ height: "400px", width: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[mapLocation.latitude, mapLocation.longitude]}
                icon={locationOnIcon}
              >
                {/* <Popup>
                Location: {mapLocation.latitude}, {mapLocation.longitude}
              </Popup> */}
              </Marker>
            </MapContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMapModal} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
